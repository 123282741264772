import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "./Banner";
import HotDeal from "./HotDeal";
import FeatureProduct from "./FeatureProduct";

const Index = () => {
  return (
    <div>
      <Header />
      <main>
        <Banner />
        <HotDeal />
        <FeatureProduct />
      </main>
      <Footer />
    </div>
  );
};

export default Index;
