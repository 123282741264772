import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { useApi } from "../../Context/ApiProvider";
import Loader from "../../Common/Loader";

const PrivacyPolicy = () => {
  const [privacyData, setPrivacyData] = useState("");
  const [loading, setLoading] = useState(true);
  const api = useApi();

  const fetchPrivacyData = async () => {
    try {
      const response = await api.get("cms/ByCMSName", "title=privacypolicy");
      setPrivacyData(response.body.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching about data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrivacyData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <main>
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="fa fa-home"></i>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Privacy Policy
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding">
          <div className="container">{privacyData?.description}</div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
