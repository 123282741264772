import React from "react";
import Slider from "react-slick";

const Banner = () => {
  const CustomPrevArrow = (props) => (
    <button
      type="button"
      className="slick-arrow"
      style={{ ...arrowStyle, ...prevArrowStyle }}
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-left"></i>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      type="button"
      className="slick-arrow"
      style={{ ...arrowStyle, ...nextArrowStyle }}
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-right"></i>
    </button>
  );

  const arrowStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    color: "#000", // Adjust color as needed
    cursor: "pointer",
    zIndex: 1, // Adjust the z-index as needed
    transition: "opacity 0.3s ease",
    opacity: 0.7, // Initial opacity
    fontSize: "70px",
  };

  const prevArrowStyle = {
    left: "60px",
  };

  const nextArrowStyle = {
    left: "92%",
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="slider-area">
      <div className="hero-slider-active slick-arrow-style slick-arrow-style_hero slick-dot-style">
        <Slider {...settings} className="slider banner">
          <div className="hero-single-slide hero-overlay">
            <div
              className="hero-slider-item bg-img"
              style={{
                backgroundImage: `url(assets/img/slider/patolapalace-slide1.jpg)`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="hero-slider-content slide-1">
                      <h2 className="slide-title">
                        Family Jewelry <span>Collection</span>
                      </h2>
                      <h4 className="slide-desc">
                        Designer Jewelry Necklaces-Bracelets-Earings
                      </h4>
                      <Link to="/" className="btn btn-hero">
                        Read More
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-single-slide hero-overlay">
            <div
              className="hero-slider-item bg-img"
              data-bg="assets/img/slider/patolapalace-slide3.jpg"
              style={{
                backgroundImage: `url(assets/img/slider/patolapalace-slide3.jpg)`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="hero-slider-content slide-1">
                      <h2 className="slide-title">
                        Family Jewelry <span>Collection</span>
                      </h2>
                      <h4 className="slide-desc">
                        Designer Jewelry Necklaces-Bracelets-Earings
                      </h4>
                      <Link to="/" className="btn btn-hero">
                        Read More
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-single-slide hero-overlay">
            <div
              className="hero-slider-item bg-img"
              data-bg="assets/img/slider/patolapalace-slide4.jpg"
              style={{
                backgroundImage: `url(assets/img/slider/patolapalace-slide4.jpg)`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="hero-slider-content slide-1">
                      <h2 className="slide-title">
                        Family Jewelry <span>Collection</span>
                      </h2>
                      <h4 className="slide-desc">
                        Designer Jewelry Necklaces-Bracelets-Earings
                      </h4>
                      <Link to="/" className="btn btn-hero">
                        Read More
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Banner;
