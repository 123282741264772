import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useApi } from "../../Context/ApiProvider";
import { base_url, no_image_url } from "../../Common/BaseURL";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";

// Importing react-geolocated for geolocation data
import { useGeolocated } from "react-geolocated";

const CustomPrevArrow = (props) => (
  <button
    type="button"
    className="slick-arrow custom-left"
    onClick={props.onClick}
    style={{
      fontSize: "30px",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
    }}
  >
    <i className="pe-7s-angle-left"></i>
  </button>
);

const CustomNextArrow = (props) => (
  <button
    type="button"
    className="slick-arrow custom-right"
    onClick={props.onClick}
    style={{
      fontSize: "30px",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
    }}
  >
    <i className="pe-7s-angle-right"></i>
  </button>
);

const ProductDetail = (props) => {
  const shareUrl = "https://www.pakkamarwadi.tk/";
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);
  const api = useApi();
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    prevArrow: data ? <CustomPrevArrow /> : null,
    nextArrow: data ? <CustomNextArrow /> : null,
  };

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const goToSlide = (slideIndex) => {
    sliderRef1.current.slickGoTo(slideIndex);
  };

  const fetchProductData = async () => {
    try {
      const productId = window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
      );

      const response = await api.get(
        `Product/GetProductDetail`,
        "productNumber=" + productId
      );

      if (response.body.success) {
        setData(response.body.data);
      } else {
        setData(null); // or handle error condition
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleFetchData = () => {
      fetchProductData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Function to fetch user data
  const getUserData = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const ipData = await response.json();
      const ip = ipData.ip;
      const os = navigator.platform;
      let location = ""; // Initialize an empty string for location
      if (coords) {
        location = `${coords.latitude}, ${coords.longitude}`; // Only access coords if it's not undefined
      }
      const pageName = "ProductDetail";
      const requestURL = window.location.href;
      const userAgent = navigator.userAgent;

      console.log("User IP Address:", ip);
      console.log("User OS:", os);
      console.log("User Location:", location);
      console.log("Page Name:", pageName);
      console.log("Request URL:", requestURL);
      console.log("User Agent:", userAgent);

      // Send this data to your backend or analytics service if needed
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Effect hook to fetch product data and user data
  useEffect(() => {
    const handleFetchData = async () => {
      getUserData();
    };

    handleFetchData();

    // Clean-up function
    return () => {
      // Any clean-up logic if needed
    };
  }, []);

  const handleCheckInStock = () => {
    const productNumber = data?.productNumber;
    const whatsappUrl = `https://wa.me/919726637739?text=${productNumber}+is+available+in+stock ?`;
    window.open(whatsappUrl, "_blank");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <main>
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="fa fa-home"></i>
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/product">Product</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        product details
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shop-main-wrapper section-padding pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 order-1 order-lg-2">
                <div className="product-details-inner">
                  <div className="row">
                    <div className="col-lg-5">
                      <div
                        className="product-large-slider"
                        style={{
                          marginBottom: "10px",
                          // border: "1px solid #ccc",
                        }}
                      >
                        {data?.productImages?.length > 1 ? (
                          <Slider
                            asNavFor={nav2}
                            ref={sliderRef1}
                            {...settings}
                            arrows={false}
                          >
                            {data?.productImages?.map((item, idx) => (
                              <div className="pro-large-img img-zoom" key={idx}>
                                {/* {isVideo(item.imageName) ? (
                                  <video
                                    style={{ width: "100%" }}
                                    controls
                                    className="centerImage"
                                  >
                                    <source
                                      src={`http://devimages.devkidos.in/product/${item.imageName}`}
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : ( */}
                                <img
                                  src={base_url + item.fileName}
                                  alt="product-details"
                                  style={{ height: "378px" }}
                                />
                                {/* )} */}
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div className="">
                            {data?.productImages?.length === 1 ? (
                              <img
                                src={base_url + data?.productImages[0].fileName}
                                alt="product-details"
                                style={{ height: "378px" }}
                              />
                            ) : (
                              <img src={no_image_url} alt="product-details" />
                            )}
                          </div>
                        )}
                      </div>

                      <div className="thumb-wrapper">
                        {data?.productImages?.length > 1 ? (
                          <Slider
                            {...settings}
                            asNavFor={nav1}
                            ref={sliderRef2}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                          >
                            {data?.productImages?.map((item, idx) => (
                              <div key={idx} onClick={() => goToSlide(idx)}>
                                {/* {isVideo(item.imageName) ? (
                                  <img
                                    style={{ width: "140px" }}
                                    src="/assets/img/video.png"
                                    alt="video"
                                  />
                                ) : ( */}
                                <img
                                  style={{
                                    width: "140px",
                                    height: "120px",
                                    cursor: "pointer",
                                  }}
                                  src={base_url + item.fileName}
                                />
                                {/* )} */}
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div>
                            <div className="">
                              {data?.productImages?.length === 1 ? (
                                <img
                                  src={
                                    base_url + data?.productImages[0].fileName
                                  }
                                  style={{
                                    width: "140px",
                                    height: "120px",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <img
                                  src={no_image_url}
                                  alt="product-details"
                                  style={{
                                    width: "140px",
                                    height: "120px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="product-details-des">
                        <div className="manufacturer-name">
                          Item# {data?.productNumber}
                        </div>
                        <h3 className="product-name">{data?.productName}</h3>
                        {/* <div className="ratings d-flex">
                          <span>
                            <i className="fa fa-star-o"></i>
                          </span>
                          <span>
                            <i className="fa fa-star-o"></i>
                          </span>
                          <span>
                            <i className="fa fa-star-o"></i>
                          </span>
                          <span>
                            <i className="fa fa-star-o"></i>
                          </span>
                          <span>
                            <i className="fa fa-star-o"></i>
                          </span>
                          <div className="pro-review">
                            <span>1 Reviews</span>
                          </div>
                        </div> */}
                        {/* <div className="price-box">
                          <span className="price-regular">
                            Price: ₹{data?.sellPrice}
                          </span>
                        </div> */}

                        <p className="pro-desc">{data?.productDescription}</p>

                        <div>
                          <p>Check item is available in stock or not</p>

                          <button
                            className="check-in-stock"
                            onClick={handleCheckInStock}
                          >
                            Check In Stock
                          </button>
                        </div>
                        <h6 className="note">
                          <span> Note*</span> : Items photography color may vary
                          depending on light & devices used during the
                          photography and also due to devices used to watch the
                          picture.
                        </h6>

                        {/* <div className="quantity-cart-box d-flex align-items-center">
                          <h6 className="option-title">qty:</h6>
                          <div className="quantity">
                            <div className="pro-qty">
                              <span
                                className="dec qtybtn"
                                // onClick={decrementQuantity}
                              >
                                -
                              </span>
                              <input
                                type="text"
                                value={1}
                                // onChange={(e) => setQuantity(e.target.value)}
                                readOnly
                              />
                              <span
                                className="inc qtybtn"
                                // onClick={incrementQuantity}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <div className="action_link">
                            <button className="btn btn-cart2">
                              Add to Cart
                            </button>
                          </div>
                        </div> */}
                        {/* <div className="useful-links">
                          <Link to="" data-bs-toggle="tooltip" title="Wishlist">
                            <i className="pe-7s-like"></i>wishlist
                          </Link>
                        </div> */}
                        <div className="like-icon">
                          <FacebookShareButton
                            url={shareUrl}
                            hashtag={"#portfolio..."}
                          >
                            <FacebookIcon size={40} round={true} />
                          </FacebookShareButton>

                          <WhatsappShareButton
                            url={shareUrl}
                            hashtag={"#portfolio..."}
                          >
                            <WhatsappIcon size={40} round={true} />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ProductDetail;
