import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useApi } from "../../Context/ApiProvider";
import { base_url, no_image_url } from "../../Common/BaseURL";

const FeaturedProduct = (props) => {
  const [featureProductData, setFeatureProductData] = useState([]);
  const api = useApi();

  const fetchProductList = async () => {
    try {
      const response = await api.get(
        "ProductListing/GetProductListingList",
        "ptype=feature"
      );

      setFeatureProductData(response.body.success ? response.body.data : null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const CustomPrevArrow = (props) => (
    <button
      type="button"
      className="slick-arrow custom-left"
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-left"></i>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      type="button"
      className="slick-arrow custom-right"
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-right"></i>
    </button>
  );

  const settings = {
    autoplaySpeed: 10000,
    rows: 2,
    autoplay: true,
    slidesToShow: 4,
    adaptiveHeight: true,
    // variableWidth: true,
    focusOnSelect: true, // Enables focusing on slides
    afterChange: (currentSlide, nextSlide) => {
      const arrows = document.querySelectorAll(".slick-arrow");
      arrows.forEach((arrow) => (arrow.style.display = "block")); // Display arrows
    },
    beforeChange: (currentSlide, nextSlide) => {
      const arrows = document.querySelectorAll(".slick-arrow");
      arrows.forEach((arrow) => (arrow.style.display = "block")); // Display arrows
    },
    prevArrow: featureProductData ? <CustomPrevArrow /> : null,
    nextArrow: featureProductData ? <CustomNextArrow /> : null,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderProductItems = () => (
    <Slider {...settings} className="slick-row-10">
      {featureProductData &&
        featureProductData.map((dt, index) => (
          <div className="product-item" key={index}>
            <figure className="product-thumb">
              <Link to={"/detail/" + dt.productNumber}>
                <img
                  className="pri-img"
                  src={
                    dt.productImages?.length > 0
                      ? base_url + dt.productImages[0].fileName
                      : no_image_url
                  }
                  alt={dt.productName}
                  style={{ height: 300 + "px" }}
                />
                <img
                  className="sec-img"
                  src={
                    dt.productImages?.length > 1
                      ? base_url + dt.productImages[1].fileName
                      : dt.productImages?.length > 0
                      ? base_url + dt.productImages[0].fileName
                      : no_image_url
                  }
                  alt={dt.productName}
                  style={{ height: 300 + "px" }}
                />
              </Link>
              <div className="product-badge">
                {/* <div className="product-label new">
                  <span>new</span>
                </div>
                <div className="product-label discount">
                  <span>10%</span>
                </div> */}
              </div>
              <div className="button-group">
                {/* <Link
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to wishlist"
                >
                  <i className="pe-7s-like"></i>
                </Link>
                <Link
                  to="/"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Compare"
                >
                  <i className="pe-7s-refresh-2"></i>
                </Link>
                <Link
                  to="/"
                  data-bs-toggle="modal"
                  data-bs-target="#quick_view"
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Quick View"
                  >
                    <i className="pe-7s-search"></i>
                  </span>
                </Link> */}
              </div>
              {/* <div className="cart-hover">
                <button className="btn btn-cart">Add to Cart</button>
              </div> */}
            </figure>
            <div className="product-caption text-center">
              <div className="product-identity">
                <h6 className="product-name">
                  <Link to={"/detail/" + dt.productNumber}>{dt.productName}</Link>
                </h6>
              </div>
              {/* <ul className="color-categories">
                <li>
                  <Link
                    className="c-lightblue"
                    to="#"
                    title="LightSteelblue"
                  ></Link>
                </li>
                <li>
                  <Link className="c-darktan" to="#" title="Darktan"></Link>
                </li>
                <li>
                  <Link className="c-grey" to="#" title="Grey"></Link>
                </li>
                <li>
                  <Link className="c-brown" to="#" title="Brown"></Link>
                </li>
              </ul> */}
              {/* <div className="price-box">
                <span className="price-regular">
                  ₹{dt.sellPrice.toFixed(2)}
                </span>
              </div> */}
            </div>
          </div>
        ))}
    </Slider>
  );

  return (
    <>
      <section className="feature-product section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title">featured products</h2>
                <p className="sub-title">
                  Add featured products to weekly lineup
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12" id="tabfet">
              {renderProductItems()}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FeaturedProduct;
