import React, { useEffect, useState } from "react";
import { useApi } from "../Context/ApiProvider";

const useProductLogic = () => {
  let PageSize = 12;
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [viewMode, setViewMode] = useState("grid");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const api = useApi();

  // Add toggle to change view
  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const getProducts = async () => {
    const url = "Product/GetProductList";
    const params = {
      currentPage: 1,
      pageSize: PageSize,
      categories: selectedCategories.join(";"),
      colors: selectedColors.join(";"),
    };

    try {
      setLoading(true);
      const response = await api.get(url, params);
      const data = response.body.success ? response.body.data : null;
      const total = response.body.success ? response.body.recordsFiltered : 0;
      setPageCount(Math.ceil(total / PageSize));
      setData(data);
      setTotalData(response.body);
    } catch (error) {
      console.error("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [api, selectedCategories, selectedColors]);

  const fetchProducts = async (currentPage) => {
    const response = await api.get(
      "Product/GetProductList",
      "currentPage=" + currentPage + "&pageSize=" + PageSize
    );
    const data = (await response.body.success) ? response.body.data : null;
    return data;
  };

  const handlePageClick = async (data) => {
    try {
      let currentPage = data.selected + 1;
      const commentsFormServer = await fetchProducts(currentPage);
      setData(commentsFormServer);
      setCurrentPage(data.selected);
      setCurrentSize(currentPage * PageSize);
      setCurrentPages(PageSize * (currentPage - 1) + 1);
    } catch (error) {
      console.log(error.message);
    }
  };

  // Helper function to organize flat categories into hierarchical structure
  const organizeCategories = (flatCategories) => {
    // Create a map to store categories by their IDs
    const categoryMap = new Map();
    flatCategories.forEach((category) => {
      categoryMap.set(category.categoryId, { ...category, children: [] });
    });

    // Populate children array for each category based on parentCategoryId
    const organizedCategories = [];
    flatCategories.forEach((category) => {
      if (category.parentCategoryId === 0) {
        organizedCategories.push(categoryMap.get(category.categoryId));
      } else {
        const parentCategory = categoryMap.get(category.parentCategoryId);
        if (parentCategory) {
          parentCategory.children.push(categoryMap.get(category.categoryId));
        }
      }
    });

    return organizedCategories;
  };

  // Fetch data for category list
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const categoriesData = await api.get("Category/GetCategoryList");
        if (categoriesData.body.success) {
          const flatCategories = categoriesData.body.data;
          const organizedCategories = organizeCategories(flatCategories);
          setCategories(organizedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [api]);

  // Fetch data for category list
  useEffect(() => {
    const fetchColor = async () => {
      try {
        setLoading(true);
        const colorData = await api.get("Color/GetColorList");
        const result = colorData.body.success ? colorData.body.data : [];

        setColors(result);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchColor();
  }, [api]);

  const handleCategoryChange = (categoryId, categoryName) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  // Function for get selected categories
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryParams = urlParams.get("categories");

    if (categoryParams) {
      const selectedCategories = categoryParams
        .split(";")
        .map((id) => parseInt(id, 10));

      setSelectedCategories(selectedCategories);
    }
  }, []);

  // Function for set selected category in URL
  useEffect(() => {
    // Update the URL with selected categories, or remove the parameter if no categories are selected
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (selectedCategories.length > 0) {
      params.set("categories", selectedCategories.join(";"));
    } else {
      params.delete("categories");
    }

    url.search = params.toString();
    window.history.pushState({}, "", url.href);
  }, [selectedCategories]);

  const handleColorChange = (colorId, colorName) => {
    if (selectedColors.includes(colorId)) {
      setSelectedColors(selectedColors.filter((id) => id !== colorId));
    } else {
      setSelectedColors([...selectedColors, colorId]);
    }
  };

  // Function for get selected color
  useEffect(() => {
    // Parse URL to extract selected color
    const urlParams = new URLSearchParams(window.location.search);
    const colorParams = urlParams.get("colors");

    if (colorParams) {
      // If color parameter is present, split it into an array of selected color
      const selectedColors = colorParams.split(";");

      // Update metalsData state with selected color
      setSelectedColors(selectedColors);
    }
  }, []);

  // Function for set selected colors in URL
  useEffect(() => {
    // Update the URL with selected color, or remove the parameter if no color are selected
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (selectedColors.length > 0) {
      params.set("colors", selectedColors.join(";"));
    } else {
      params.delete("colors");
    }

    url.search = params.toString();
    window.history.pushState({}, "", url.href);
  }, [selectedColors]);

  return {
    data,
    totalData,
    pageCount,
    viewMode,
    currentPage,
    currentSize,
    currentPages,
    loading,
    categories,
    colors,
    toggleViewMode,
    handlePageClick,
    handleCategoryChange,
    handleColorChange,
    PageSize,
    selectedCategories,
    selectedColors,
  };
};

export default useProductLogic;
