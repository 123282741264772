import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useApi } from "../../Context/ApiProvider";
import { base_url, no_image_url } from "../../Common/BaseURL";

const HotDeal = () => {
  const [hotDealProductData, setHotDealProductData] = useState([]);
  const api = useApi();

  const hotProductList = async () => {
    try {
      const response = await api.get(
        "ProductListing/GetProductListingList",
        "ptype=hotdeal"
      );

      setHotDealProductData(response.body.success ? response.body.data : null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    hotProductList();
  }, []);

  const CustomPrevArrow = (props) => (
    <button
      type="button"
      className="slick-arrow custom-left"
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-left"></i>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      type="button"
      className="slick-arrow custom-right"
      onClick={props.onClick}
    >
      <i className="pe-7s-angle-right"></i>
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    afterChange: (currentSlide, nextSlide) => {
      const arrows = document.querySelectorAll(".slick-arrow");
      arrows.forEach((arrow) => (arrow.style.display = "block")); // Display arrows
    },
    beforeChange: (currentSlide, nextSlide) => {
      const arrows = document.querySelectorAll(".slick-arrow");
      arrows.forEach((arrow) => (arrow.style.display = "block")); // Display arrows
    },
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderProductItems = () => (
    <Slider {...settings}>
      {hotDealProductData &&
        hotDealProductData?.map((product, index) => (
          <div key={index} className="hot-deals-item product-item">
            <figure className="product-thumb">
              <Link to={"/detail/" + product.productNumber}>
                <img
                  className="pri-img"
                  src={
                    product.productImages?.length > 0
                      ? base_url + product.productImages[0].fileName
                      : no_image_url
                  }
                  alt={product.productName}
                  style={{ height: 300 + "px" }}
                />
                <img
                  className="sec-img"
                  src={
                    product.productImages?.length > 1
                      ? base_url + product.productImages[1].fileName
                      : product.productImages?.length > 0
                      ? base_url + product.productImages[0].fileName
                      : no_image_url
                  }
                  alt={product.productName}
                  style={{ height: 300 + "px" }}
                />
              </Link>
              {/* <div className="product-badge">
                <div className="product-label new">
                  <span>sale</span>
                </div>
                <div className="product-label discount">
                  <span>new</span>
                </div>
              </div> */}
              <div className="button-group">
                {/* <Link
                  to="/"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to wishlist"
                >
                  <i className="pe-7s-like"></i>
                </Link> */}
                {/* <Link
                  to="/"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Compare"
                >
                  <i className="pe-7s-refresh-2"></i>
                </Link> */}
                {/* <Link
                  to="/"
                  data-bs-toggle="modal"
                  data-bs-target="#quick_view"
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Quick View"
                  >
                    <i className="pe-7s-search"></i>
                  </span>
                </Link> */}
              </div>
              {/* <div className="cart-hover">
                <button className="btn btn-cart">add to cart</button>
              </div> */}
            </figure>
            <div className="product-caption text-center">
              <div className="product-identity">
                <h6 className="product-name">
                  <Link to={"/detail/" + product.productNumber}>
                    {product.productName}
                  </Link>
                </h6>
              </div>
              {/* <div className="price-box">
                <span className="price-regular">
                  ₹{product.sellPrice.toFixed(2)}
                </span>
                  <span className="price-old">
                  <del>$70.00</del>
                </span>  
              </div> */}
            </div>
          </div>
        ))}
    </Slider>
  );

  return (
    <section className="hot-deals section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h2 className="title">Hot deals</h2>
              <p className="sub-title">
                Add featured products to weekly lineup
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{renderProductItems()}</div>
        </div>
      </div>
    </section>
  );
};

export default HotDeal;
