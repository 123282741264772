import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./Components/Home/Index";
import Products from "./Pages/Products/Products";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import ApiProvider from "./Context/ApiProvider";
import AboutUs from "./Pages/AboutUs/AboutUs";
import DeliveryInformation from "./Pages/Delivery/DeliveryInformation";
import PrivacyPolicy from "./Pages/Privacy/PrivacyPolicy";
import TermsCondition from "./Pages/Terms/TermsCondition";
import ContactUs from "./Pages/ContactUs/ContactUs";

function App() {
  useEffect(() => {
    const handleRightClick = (event) => {
      event.preventDefault(); // Prevent the right-click menu from appearing
    };

    document.addEventListener("contextmenu", handleRightClick);

    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 121) {
        // Prevent F12
        event.preventDefault(); // Prevent default behavior (opening developer tools)
      } else if (event.keyCode === 123) {
        // Prevent F12
        event.preventDefault(); // Prevent default behavior (opening developer tools)
      } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        // Prevent Ctrl+Shift+I
        event.preventDefault(); // Prevent default behavior (opening developer tools)
      } else if (event.ctrlKey && event.shiftKey && event.keyCode === 74) {
        // Prevent Ctrl+Shift+J
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <ApiProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/product" element={<Products />} />
          <Route path="/detail/:id" element={<ProductDetail />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/delivery-information" element={<DeliveryInformation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
    </ApiProvider>
  );
}

export default App;
