import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useApi } from "../../Context/ApiProvider";
import Loader from "../../Common/Loader";

const ContactUs = () => {
  const [contactData, setContactData] = useState("");
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    first_name: "",
    phone: "",
    email_address: "",
    contact_subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    phone: "",
    email_address: "",
  });
  const api = useApi();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("cms/ByCMSName", "title=contactusinfo");
        setContactData(response.body.data);
      } catch (error) {
        console.error("Error fetching contact information:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [api]);

  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // Example phone number regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    // Validate name
    if (formData.first_name.trim() === "") {
      newErrors.first_name = "Name is required";
      valid = false;
    } else {
      newErrors.first_name = "";
    }

    // Validate phone number
    if (!formData.phone.match(phoneRegex)) {
      newErrors.phone = "Invalid phone number";
      valid = false;
    } else {
      newErrors.phone = "";
    }

    // Validate email
    if (!formData.email_address.match(emailRegex)) {
      newErrors.email_address = "Invalid email address";
      valid = false;
    } else {
      newErrors.email_address = "";
    }

    // Validate name
    if (formData.contact_subject.trim() === "") {
      newErrors.contact_subject = "Subject is required";
      valid = false;
    } else {
      newErrors.contact_subject = "";
    }

    // Validate name
    if (formData.message.trim() === "") {
      newErrors.message = "Message is required";
      valid = false;
    } else {
      newErrors.message = "";
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      return;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <main>
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="fa fa-home"></i>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        contact us
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-message">
                  <h4 className="contact-title">Tell Us Your Query/Suggestion</h4>
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit}
                    className="contact-form"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <input
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleInputChange}
                          placeholder="Name *"
                          type="text"
                        />
                        {formErrors.first_name && (
                          <p className="error-message">
                            {formErrors.first_name}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <input
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          placeholder="Phone *"
                          type="text"
                        />
                        {formErrors.phone && (
                          <p className="error-message">{formErrors.phone}</p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <input
                          name="email_address"
                          value={formData.email_address}
                          onChange={handleInputChange}
                          placeholder="Email *"
                          type="text"
                        />
                        {formErrors.email_address && (
                          <p className="error-message">
                            {formErrors.email_address}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <input
                          name="contact_subject"
                          value={formData.contact_subject}
                          onChange={handleInputChange}
                          placeholder="Subject *"
                          type="text"
                        />
                        {formErrors.contact_subject && (
                          <p className="error-message">
                            {formErrors.contact_subject}
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <div className="contact2-textarea text-center">
                          <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Message *"
                            className="form-control2"
                          ></textarea>
                        </div>
                        {formErrors.message && (
                          <p className="error-message">{formErrors.message}</p>
                        )}
                        <div className="contact-btn">
                          <button className="btn btn-sqr" type="submit">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                {contactData?.description}
                {/* <div dangerouslySetInnerHTML={data} /> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;
