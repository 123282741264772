import React from "react";
import { Circles } from "react-loader-spinner";

const Loader = () => {
  const myStyle = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    fontFamily: "Arial",
    opacity: "0.8",
  };

  return (
    <div className="loader-container" style={myStyle}>
      <Circles
        height="100"
        width="100"
        color="#4fa94d"
        outerCircleColor="#4fa94d"
        innerCircleColor="#4fa94d"
        barColor="#4fa94d"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={{
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
        }}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
