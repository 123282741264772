import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import useProductLogic from "../../Hooks/useProductLogic";
import { base_url, no_image_url } from "../../Common/BaseURL";

const Products = () => {
  // State to manage which dropdowns are open
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const {
    data,
    totalData,
    pageCount,
    viewMode,
    currentPage,
    currentSize,
    currentPages,
    loading,
    categories,
    colors,
    toggleViewMode,
    handlePageClick,
    handleCategoryChange,
    handleColorChange,
    PageSize,
    selectedCategories,
    selectedColors,
  } = useProductLogic();

  if (loading) {
    return <Loader />;
  }

  // Function to toggle dropdown open/close
  const toggleDropdown = (categoryId, categoryName) => {
    if (openDropdowns.includes(categoryId)) {
      setOpenDropdowns(openDropdowns.filter((id) => id !== categoryId));
    } else {
      setOpenDropdowns([...openDropdowns, categoryId]);
    }
  };

  // Recursive function to render categories and their child categories
  const renderCategories = (categories) => (
    <div className="custom-control custom-checkbox">
      {categories.map((category) => (
        <div key={category.categoryId}>
          {category.parentCategoryId === 0 ? (
            <div>
              <p
                onClick={() =>
                  toggleDropdown(category.categoryId, category.categoryName)
                }
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {category.categoryName}{" "}
                {category.children.length > 0 && (
                  <img
                    src={
                      openDropdowns.includes(category.categoryId)
                        ? "/assets/img/up-chevron.svg"
                        : "/assets/img/down-chevron.svg"
                    }
                    alt={
                      openDropdowns.includes(category.categoryId)
                        ? "up"
                        : "down"
                    }
                    style={{ width: "15px" }}
                  />
                )}
              </p>
            </div>
          ) : (
            <li style={{ paddingLeft: "1.5rem" }}>
              <input
                type="checkbox"
                className="custom-control-input"
                id={`category-${category.categoryId}`}
                checked={selectedCategories.includes(category.categoryId)}
                onChange={() =>
                  handleCategoryChange(
                    category.categoryId,
                    category.categoryName
                  )
                }
              />
              <label
                className="custom-control-label"
                htmlFor={`category-${category.categoryId}`}
              >
                {category.categoryName}
              </label>
            </li>
          )}
          {category.parentCategoryId !== 0 && category.children.length > 0 && (
            <ul>
              <li>{renderCategories(category.children)}</li>
            </ul>
          )}
          {openDropdowns.includes(category.categoryId) &&
            renderCategories(category.children)}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Header />
      <main>
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="fa fa-home"></i>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Product
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shop-main-wrapper section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-2 order-lg-1">
                <aside className="sidebar-wrapper">
                  <div className="sidebar-single">
                    <h5 className="sidebar-title">categories</h5>
                    <div className="sidebar-body">
                      <ul className="checkbox-container shop-categories">
                        {renderCategories(categories)}
                      </ul>
                    </div>
                  </div>
                  <div className="sidebar-single">
                    <h5 className="sidebar-title">Colors</h5>
                    <div className="sidebar-body">
                      <ul className="checkbox-container categories-list">
                        {colors &&
                          colors.map((color, index) => (
                            <li key={index}>
                              <div
                                className="custom-control custom-checkbox"
                                style={{ paddingLeft: "1.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`color-${color.colorName}`}
                                  checked={selectedColors.includes(
                                    color.colorCode
                                  )}
                                  onChange={() =>
                                    handleColorChange(
                                      color.colorCode,
                                      color.colorName
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`color-${color.colorName}`}
                                >
                                  {color.colorName}
                                </label>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>

              <div className="col-lg-9 order-1 order-lg-2">
                <div className="shop-product-wrapper">
                  <div className="shop-top-bar">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-md-6 order-2 order-md-1">
                        <div className="top-bar-left">
                          <div className="product-view-mode">
                            <Link
                              className={viewMode === "grid" ? "active" : ""}
                              onClick={() => toggleViewMode("grid")}
                              // to='javascript:void(0)'
                              data-target="grid-view"
                              data-bs-toggle="tooltip"
                              title="Grid View"
                            >
                              <i className="fa fa-th"></i>
                            </Link>
                            <Link
                              className={viewMode === "list" ? "active" : ""}
                              onClick={() => toggleViewMode("list")}
                              // to='javascript:void(0)'
                              data-target="list-view"
                              data-bs-toggle="tooltip"
                              title="List View"
                            >
                              <i className="fa fa-list"></i>
                            </Link>
                          </div>
                          <div className="product-amount">
                            {currentPage === 0 ? (
                              <p>
                                Showing {currentPage + 1} - {totalData.recordsFiltered} of{" "}
                                {totalData.recordsTotal} results
                              </p>
                            ) : (
                              <p>
                                Showing {currentPages} - {currentSize} of{" "}
                                {totalData.recordsTotal} results
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-5 col-md-6 order-1 order-md-2">
                        <div className="top-bar-right">
                          <div className="product-short">
                            <p>Sort By : </p>
                            <select className="nice-select" name="sortby">
                              <option value="trending">Relevance</option>
                              <option value="sales">Name (A - Z)</option>
                              <option value="sales">Name (Z - A)</option>
                              <option value="rating">
                                Price (Low &gt; High)
                              </option>
                              <option value="date">Rating (Lowest)</option>
                              <option value="price-asc">Model (A - Z)</option>
                              <option value="price-asc">Model (Z - A)</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className={`${
                      viewMode === "grid" ? "grid-view" : "list-view"
                    } shop-product-wrap row mbn-30`}
                  >
                    {data && data.length > 0 ? (
                      data.map((dt, index) => (
                        <div className="col-md-4 col-sm-6" key={index}>
                          <div className="product-item">
                            <figure className="product-thumb">
                              <Link to={"/detail/" + dt.productNumber}>
                                <img
                                  className="pri-img"
                                  src={
                                    dt.productImages?.length > 0
                                      ? base_url + dt.productImages[0].fileName
                                      : no_image_url
                                  }
                                  alt={dt.fileName}
                                  style={{ height: 300 + "px" }}
                                />
                                <img
                                  className="sec-img"
                                  src={
                                    dt.productImages?.length > 0
                                      ? base_url + dt.productImages[0].fileName
                                      : base_url + dt.productImages[0]?.fileName
                                  }
                                  alt={dt.fileName}
                                  style={{ height: 300 + "px" }}
                                />
                              </Link>
                              {/* <div className="product-badge">
                                <div className="product-label new">
                                  <span>new</span>
                                </div>
                                <div className="product-label discount">
                                  <span>10%</span>
                                </div>
                              </div> */}
                              {/* <div className="button-group">
                                <Link
                                  to="#"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Add to wishlist"
                                >
                                  <i className="pe-7s-like"></i>
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Add to Compare"
                                >
                                  <i className="pe-7s-refresh-2"></i>
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quick_view"
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Quick View"
                                  >
                                    <i className="pe-7s-search"></i>
                                  </span>
                                </Link>
                              </div> */}
                              {/* <div className="cart-hover">
                                <button className="btn btn-cart">
                                  add to cart
                                </button>
                              </div> */}
                            </figure>
                            <div className="product-caption text-center">
                              <div className="product-identity">
                                <p className="manufacturer-name">
                                  <Link to={"/detail/" + dt.productNumber}>
                                    {dt.productName}
                                  </Link>
                                </p>
                              </div>
                              {/* <div className="price-box">
                                <span className="price-regular">
                                  ₹{dt.sellPrice.toFixed(2)}
                                </span>
                              </div> */}
                            </div>
                          </div>
                          <div className="product-list-item">
                            <figure className="product-thumb">
                              <Link to={"/detail/" + dt.productNumber}>
                                <img
                                  className="pri-img"
                                  src={
                                    dt.productImages?.length > 0
                                      ? base_url + dt.productImages[0].fileName
                                      : no_image_url
                                  }
                                  alt={dt.fileName}
                                />

                                <img
                                  className="sec-img"
                                  src={
                                    dt.productImages?.length > 0
                                      ? base_url + dt.productImages[0].fileName
                                      : no_image_url
                                  }
                                  alt={dt.fileName}
                                />
                              </Link>
                              {/* <div className="product-badge">
                                <div className="product-label new">
                                  <span>new</span>
                                </div>
                                <div className="product-label discount">
                                  <span>10%</span>
                                </div>
                              </div> */}
                              {/* <div className="button-group">
                                <Link
                                  to="#"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Add to wishlist"
                                >
                                  <i className="pe-7s-like"></i>
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Add to Compare"
                                >
                                  <i className="pe-7s-refresh-2"></i>
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quick_view"
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Quick View"
                                  >
                                    <i className="pe-7s-search"></i>
                                  </span>
                                </Link>
                              </div> */}
                              {/* <div className="cart-hover">
                                <button className="btn btn-cart">
                                  add to cart
                                </button>
                              </div> */}
                            </figure>
                            <div className="product-content-list">
                              <div className="manufacturer-name">
                                <Link to={"/detail/" + dt.productNumber}>
                                  {dt.productName}
                                </Link>
                              </div>
                              {/* <div className="price-box">
                                <span className="price-regular">
                                  ₹{dt.sellPrice.toFixed(2)}
                                </span>
                              </div> */}
                              <p>{data.productDescription}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-data-found">
                        <p>Data not found in your search criteria</p>
                      </div>
                    )}
                  </div>

                  {data && data.length > 0 && (
                    <div className="paginatoin-area text-center">
                      <ReactPaginate
                        previousLabel={<i className="pe-7s-angle-left"></i>}
                        nextLabel={<i className="pe-7s-angle-right"></i>}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination-box"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Products;
